export const mtnExtensions = [
    "467",
    "077",
    "078",
    "039",
    "031",
    "076",
    "25677",
    "25678",
    "25639",
    "25631",
    "25676",
    "25611",
]


export const isMTN = (number?: string | null) => {
    if (!number)
        return false
    return mtnExtensions.some(it => number.startsWith(it))
}

export const splitPhone = (phones: string) => {
    const regex = /^[0-9]+$/g;
    return phones.split(",").map(it => {
        const [found] = it.match(regex) || []
        return found
    }).filter(Boolean)
}
