import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import {Link, useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {printFloatNumber} from "../../utils/numberHelpers";
import Fab from '@material-ui/core/Fab';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {useSelector} from "react-redux";
import {AppState} from "../../data/types";
import {useCalculatorStyles} from "./styles";
import AmountPicker from "./components/AmountPicker";
import {computeLoanPayment} from "./helpers";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import Button from "@material-ui/core/Button";
import {localRoutes} from "../../data/constants";

interface IProps {
    onApply: (amount: number) => void
}

interface IPreviewProps {
    interest: number
    interestRate: number
}

export const Preview = ({interest}: IPreviewProps) => {
    const classes = useCalculatorStyles();
    return <div className={classes.preview}>
        <Box width='100%' className={classes.previewBox}>
            <Typography variant='h5' align="center">
                {printFloatNumber(interest)}
            </Typography>
            <Typography variant='body2' align="center" component='div'>
                minimum interest amount
            </Typography>
        </Box>
    </div>
}

const LoanCalculator = (props: IProps) => {
    const isLoggedIn = useSelector((state: AppState) => state.core.user);
    const classes = useCalculatorStyles();
    const history = useHistory();
    const loanSettings = useSelector((state: AppState) => state.loans.loanSettings)
    const [amount, setAmount] = useState<any>(loanSettings.maxAmount! / 2)
    const handleChange = (value: number) => {
        setAmount(value)
    }

    const {interest} = computeLoanPayment(amount, loanSettings);

    function handleGetNow() {
        props.onApply(amount)
    }

    function handleCreateAccount(e: any) {
        e.preventDefault()
        history.push(localRoutes.employeeRegister)
    }

    return (
        <div className={classes.root}>
            <HiddenJs smDown>
                <Box display='flex' justifyContent='flex-end' style={{position: 'relative', height: 50}}>
                    <Preview
                        interest={interest}
                        interestRate={loanSettings.interestRate!}
                    />
                </Box>
            </HiddenJs>
            <div className={classes.calculatorHolder}>
                <HiddenJs mdUp>
                    <Box display='flex' width='100%' justifyContent='center' className={classes.calculatorHolderBox}>
                        <Preview
                            interest={interest}
                            interestRate={loanSettings.interestRate!}
                        />
                    </Box>
                </HiddenJs>
                <div className={classes.calculator}>
                    <Box width="100%" className={classes.calculatorBox}>
                        <Typography component="h1" variant="h5" align="center">
                            Loan Calculator
                        </Typography>
                    </Box>
                    <AmountPicker
                        loanSettings={loanSettings}
                        onChange={handleChange}
                        value={amount}
                    />
                    <Box width="100%" pt={2} display='flex' justifyContent='center'>
                        <Fab variant="extended"
                             color="primary"
                             aria-label="add"
                             className={classes.button}
                             onClick={handleGetNow}
                        >
                            <AccountBalanceWalletIcon className={classes.extendedIcon}/>
                            Get it now&nbsp;&nbsp;
                        </Fab>
                    </Box>
                    <Box width="100%" py={2} display='flex' justifyContent='center'>
                        <Box width={isLoggedIn ? "100%" : "50%"} display='flex' justifyContent='center'>
                            <Button
                                color='primary'
                                size="large"
                                variant='text'
                                component={Link}
                                to={localRoutes.pay}
                                fullWidth
                            >Pay loan</Button>
                        </Box>
                        {
                            !isLoggedIn &&
                            <Box width="50%" display='flex' justifyContent='center'>
                                <Button
                                    variant='text'
                                    color="default"
                                    size="large"
                                    className={classes.button}
                                    onClick={handleCreateAccount}
                                    fullWidth
                                >
                                    <span className={classes.default_text}>Register</span>
                                </Button>
                            </Box>
                        }
                    </Box>

                </div>
            </div>
        </div>
    );
}


export default LoanCalculator;


