import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Form, Formik, FormikHelpers} from 'formik';

import * as yup from "yup";
import {post} from "../../utils/ajax";
import {localRoutes, remoteRoutes} from "../../data/constants";
import XTextInput from "../../components/inputs/XTextInput";
import {useLoginStyles} from "./loginStyles";
import Box from "@material-ui/core/Box";
import {Alert} from "@material-ui/lab";
import {Link} from "react-router-dom";

const initialValues =
    {
        "email": ""
    }

const schema = yup.object().shape(
    {
        email: yup.string()
            .email('Invalid email')
            .required("Email is required")
    }
);

function ForgotPassword() {
    const classes = useLoginStyles();
    const [success, setSuccess] = useState<boolean>(false)
    const onSubmit = (data: any, actions: FormikHelpers<any>) => {
        post(remoteRoutes.forgotPassword, data, () => {
            actions.setSubmitting(false)
            setSuccess(true)
        },undefined,()=>{
            actions.setSubmitting(false)
        })
    }

    return (
        <Box display='flex' justifyContent='center'>
            <Box>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1">
                        Forgot password
                    </Typography>
                    {
                        success ?
                            <Box p={1}>
                                <Alert >A request to reset your password has been sent to your email.</Alert>
                                <Box display="flex" justifyContent='center' pt={2}>
                                    <Link to={localRoutes.login}>Login</Link>
                                </Box>
                            </Box> :

                            <Formik
                                initialValues={initialValues}
                                validationSchema={schema}
                                onSubmit={onSubmit}
                            >{
                                (formState) => {
                                    return <Form className={classes.form}>
                                        <Alert severity='info'>
                                            Enter the email address you used to signup and we shall send you an email to reset your password.
                                        </Alert>

                                        <XTextInput
                                            type='email'
                                            name='email'
                                            label='Email'
                                            autoComplete="email"
                                            autoFocus
                                            margin="normal"
                                            variant='outlined'
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            disabled={formState.isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                        <Box display="flex" justifyContent='center' pt={2}>
                                            <Link to={localRoutes.login}>Login</Link>
                                        </Box>
                                    </Form>
                                }
                            }
                            </Formik>
                    }
                </Paper>
            </Box>
        </Box>
    );
}

export default ForgotPassword


