import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {Link, useLocation} from 'react-router-dom'

import {post} from "../../utils/ajax";
import {localRoutes, remoteRoutes} from "../../data/constants";
import {useLoginStyles} from "./loginStyles";
import Box from "@material-ui/core/Box";
import Loading from "../../components/loaders/Loading";
import {Alert} from "@material-ui/lab";


function Activate() {
    let {search} = useLocation();
    const classes = useLoginStyles();
    const [loading, setLoading] = useState<boolean>(true)
    const [success, setSuccess] = useState<boolean>(false)


    useEffect(() => {
        const query = new URLSearchParams(search)
        const token = query.get("token")
        const userId = query.get("userId")
        if (!token || !userId) {
            setLoading(false)
            setSuccess(false)
            console.error("No token found")
            return;
        }
        setLoading(true)
        post(remoteRoutes.activate, {token, userId}, (resp: any) => {
            console.log("",resp);
            setLoading(false)
            setSuccess(true)
        }, undefined, () => {
            setLoading(false)
        })
    }, [search])

    let message = <Loading/>
    if (!loading && !success)
        message = <Alert severity="error">Oops something went wrong, please contact your administrator</Alert>
    else if (!loading && success)
        message = <Box>
            <Alert severity="success">Your account has been successfully activated</Alert>
            <Box display="flex" justifyContent='center' pt={2}>
                <Link to={localRoutes.login}>Login</Link>
            </Box>
        </Box>
    return (
        <Box display='flex' justifyContent='center'>
            <Box>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1">
                        Activate your account
                    </Typography>
                    <Box p={2}>
                        {message}
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

export default Activate


