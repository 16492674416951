import React, {useMemo} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {InvoiceViewModel} from "../../../../__generated__/apiTypes";
import LiteInvoiceDetails from "./LiteInvoiceDetails";
import {Box} from "@material-ui/core";
import {hasNoValue} from "../../../../components/inputs/inputHelpers";
import DocView from "../../../../components/docs/DocView";
import PaymentsList from "./PaymentsList";

type Props = {
    onChange: (loans: string[]) => void
    value: string[] | null
    loading?: boolean
    onNext: () => void
    onBack: () => void
    data: InvoiceViewModel
    file: any
}

export default function SelectPayments({data, loading, onChange, file, ...props}: Props) {

    const [selected, setSelected] = React.useState<string[]>([]);
    const fileUrl = useMemo(() => URL.createObjectURL(file), [file])

    function onNext() {
        onChange(selected)
        props.onNext()
    }

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <LiteInvoiceDetails data={data}/>
            </Grid>
            <Grid item xs={12}>
                <Box display='flex' flexDirection="row-reverse" pr={3}>
                    <Button
                        disabled={loading || hasNoValue(selected)}
                        onClick={onNext}
                        variant='outlined'
                        color='primary'
                    >Next</Button>
                    <Button
                        style={{marginLeft: 8}}
                        disabled={loading}
                        onClick={props.onBack}
                        variant='outlined'
                        color='primary'
                    >Back</Button>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Box width="100%" display="flex" flexDirection="row-reverse">
                    <DocView url={fileUrl} loading={false} xDoc={{contentType: file.type}}/>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Box width="100%" display="flex" flexDirection="row">
                    <PaymentsList data={data} onChange={setSelected}/>
                </Box>
            </Grid>
        </Grid>
    );
}
