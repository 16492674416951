import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import {AppState, AppUser, LoginResponse} from "../../data/types";
import {Grid, useMediaQuery} from "@material-ui/core";
import {useCalculatorStyles} from "./styles";
import XMaskedInput from "../../components/inputs/XMaskedInput";
import {hasNoValue, hasValue} from "../../components/inputs/inputHelpers";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Fab from '@material-ui/core/Fab';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Box from "@material-ui/core/Box";
import {Alert} from "@material-ui/lab";
import {printFloatNumber, printInteger} from "../../utils/numberHelpers";
import {computeLoanPayment} from "./helpers";
import {homeSteps, ILoanPayment, IWebAppLoanRequest} from "./types";
import Typography from "@material-ui/core/Typography";
import {handleBadRequestError, handleError, post} from "../../utils/ajax";
import {
    APP_NAME_SHORT,
    AUTH_KEY_TOKEN,
    AUTH_KEY_USER,
    localRoutes,
    remoteRoutes,
    SupportEmail,
    SupportPhone,
    termsLink
} from "../../data/constants";
import Toast from "../../utils/Toast";
import {addLoanSettings} from "../../data/redux/loans/reducer";
import {XSlider} from "../../components/inputs/XSlider";
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from "@material-ui/core/Link";
import EmailLink from "../../components/links/EmalLink";
import useTheme from "@material-ui/core/styles/useTheme";
import {PublicLoanConfig} from "../../__generated__/apiTypes";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import {RoundedTextField} from "../../components/inputs/XTextInput";
import * as superagent from "superagent";


export const useCardStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            width: 450,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        maxWidth: '100%',
        borderRadius: 32,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        }
    },
    stepper: {
        width: '100%'
    },
}));

interface IProps {
    amount: number
    onBack: () => void
}


interface InfoMessageProps {
    payment: ILoanPayment
    settings: PublicLoanConfig
}


export const inputStyle: any = {
    fontSize: "1.3rem",
}


export const InfoMessage = ({payment}: InfoMessageProps) => {
    return <Alert severity='info'>
        <Typography>
            <b>UGX&nbsp;{printFloatNumber(payment.interest)}</b> is the
            interest.&nbsp;
            A total of <b>UGX {printInteger(payment.totalPayment)}</b> will
            automatically be deducted from your salary at the end of the month.
        </Typography>
    </Alert>
}

interface SuccessMessageProps extends InfoMessageProps {
    data: any
    user: AppUser
}

export const SuccessMessage = ({payment, data, user}: SuccessMessageProps) => {
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Alert severity='info'>
                <Typography>
                    Hello {user.fullName},<br/>
                    You have requested for a loan of <b>UGX&nbsp;{printFloatNumber(payment.amount)}</b>&nbsp;
                    <br/><br/>
                    Your application reference number is <b>{data.referenceNumber}</b>
                    <br/><br/>
                    You will receive an email on <b>{user.email}</b> shortly informing you of the status of your
                    transaction.
                    <br/><br/>
                    A total of <b>UGX {printInteger(payment.totalPayment)}</b> will be
                    automatically deducted from your salary at the end of the month.
                    <br/><br/>
                    In case of any queries, please reach out to our help line at <b>{SupportPhone}</b>.&nbsp;
                    Or send us an email at <EmailLink value={SupportEmail}/>.
                    <br/><br/>
                    <Link href={termsLink} target='_blank' style={{textDecoration: 'underline'}}>Terms and
                        conditions</Link>&nbsp;
                    apply
                </Typography>
            </Alert>
        </Grid>
        <Grid item xs={12}>
            <Box width="100%" py={1} display='flex' justifyContent='center'>
                <Button color='primary' variant='text' component={RouterLink} to={localRoutes.pay}>
                    See loan
                </Button>
            </Box>
        </Grid>
    </Grid>
}

export const createMessage = (message: string): any => {
    return <Alert severity='error'>
        <Typography>
            {message}
        </Typography>
    </Alert>
}

const ApprovalStep = (props: IProps) => {
    const calcClasses = useCalculatorStyles();
    const dispatch = useDispatch();
    const classes = useCardStyles();
    const loanSettings = useSelector((state: AppState) => state.loans.loanSettings)
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [loanData, setLoanData] = useState<any | null>(null)
    const [session, setSession] = useState<any | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<any | null>(null)
    const [password, setPassword] = useState<string>("")
    const [request, setRequest] = React.useState<IWebAppLoanRequest>({
        phone: "",
        category: "WebApp",
        amount: props.amount,
        sessionId: "browser",
        shortCode: "-NA-",
        network: '-NA-',
        terms: false
    });

    const loanPayment = computeLoanPayment(request.amount, loanSettings);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'password') {
            setPassword(event.target.value)
        } else if (event.target.name === 'amount') {
            handleAmountChange(event, event.target.value)
        } else {
            setRequest({
                ...request,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleAmountChange = (e: any, value: any) => {
        const amount = Number(value);
        if (amount >= loanSettings.minAmount! && amount <= loanSettings.maxAmount!) {
            const req = {
                ...request, amount
            }
            setRequest(req);
        }
    }

    function handleAgreement(evt: any, terms: boolean) {
        const req = {
            ...request, terms
        }
        setRequest(req);
    }

    function handleApprove() {
        if (
            hasNoValue(request.phone) ||
            hasNoValue(password)
        ) {
            Toast.warn("Please enter all required fields")
            return
        }

        if (!request.terms) {
            Toast.warn("Please read and agree to the terms and conditions")
            return
        }

        if (
            request.amount > loanSettings.maxAmount! ||
            request.amount < loanSettings.minAmount!
        ) {
            Toast.warn("Invalid loan amount")
            return
        }
        handleUserLogin((session: any) => {
            //dispatch(doLogin(session))
            requestLoan(session, resp => {
                Toast.info("Loan request submitted for processing")
                setLoanData(resp)
            })
        })
    }

    function handleUserLogin(done: (dt: any) => any) {
        const login: any = {
            phone: request.phone,
            password,
        }
        setLoading(true)
        //Login
        post(remoteRoutes.loginPhone, login, resp => {
            const {token, user}: LoginResponse = resp
            localStorage.setItem(AUTH_KEY_TOKEN, token)
            localStorage.setItem(AUTH_KEY_USER, JSON.stringify(user))
            setSession({...resp})
            done(resp)
        }, (err: any = {}, res: superagent.Response)=>{
            handleError(err,res)
            setLoading(false)
        })
    }

    function requestLoan(sess: any, done: (resp: any) => any) {
        if (sess) {
            setLoading(true)
            //Login
            post(
                remoteRoutes.loansRequestLoan, request,
                resp => {
                    done(resp)
                },
                (err: any = {}, res: any) => {
                    handleBadRequestError(err, res, resp => {
                        setErrorMessage(createMessage(resp.message))
                        if (hasValue(resp.data)) {
                            dispatch(addLoanSettings(resp.data))
                        }
                        setLoading(false)
                    })
                },
                () => {
                    setLoading(false)
                })
        }
    }

    let inputPaddingX = 3;
    if (isSmall) {
        inputPaddingX = 1;
    }


    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stepper
                        activeStep={homeSteps.APPROVE_PAYOUT}
                        orientation='horizontal'
                        className={classes.stepper}
                    >
                        <Step completed>
                            <StepLabel>Choose Amount</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Approve Payout</StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
                {
                    loanData ? <Grid item xs={12}>
                        <SuccessMessage
                            payment={loanPayment}
                            settings={loanSettings}
                            data={loanData}
                            user={session.user}
                        />
                    </Grid> : <>
                        <Grid item xs={12}>
                            {
                                errorMessage || <InfoMessage
                                    payment={loanPayment}
                                    settings={loanSettings}
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Box px={inputPaddingX}>

                                <XMaskedInput
                                    label="Amount"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    name="amount"
                                    value={request.amount}
                                    onChange={handleChange}
                                    inputProps={
                                        {
                                            style: inputStyle
                                        }
                                    }
                                    disabled={loading}
                                />
                                <XSlider
                                    step={loanSettings.stepAmount}
                                    value={Number(request.amount)}
                                    min={loanSettings.minAmount}
                                    max={loanSettings.maxAmount}
                                    onChange={handleAmountChange}
                                    disabled={loading}
                                />
                                <Box width="100%" display='flex'>
                                    <Box width="50%">
                                        <Typography variant='body2' color='primary'>
                                            {printFloatNumber(loanSettings.minAmount)}
                                        </Typography>
                                    </Box>
                                    <Box width="50%" display='flex' justifyContent='flex-end'>
                                        <Typography variant='body2' color='primary'>
                                            {printFloatNumber(loanSettings.maxAmount)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box px={inputPaddingX}>
                                <RoundedTextField
                                    label="Email / Phone"
                                    autoComplete="off"
                                    variant='outlined'
                                    name='phone'
                                    fullWidth
                                    size='small'
                                    value={request.phone}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <Box px={inputPaddingX}>
                                <RoundedTextField
                                    label="Password"
                                    type="password"
                                    name='password'
                                    fullWidth
                                    size='small'
                                    autoComplete="off"
                                    variant='outlined'
                                    value={password}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box px={inputPaddingX}>
                                <FormControlLabel
                                    value="end"
                                    checked={request.terms}
                                    disabled={loading}
                                    onChange={handleAgreement}
                                    control={<Checkbox color="primary"/>}
                                    label={<Typography style={{color: 'black'}}>
                                        I have read and agree to the
                                        &nbsp;<Link href={termsLink} target='_blank'><b>terms and
                                        conditions</b></Link>&nbsp;
                                        of the {APP_NAME_SHORT} Customer Agreement
                                    </Typography>}
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box width="100%" py={2} display='flex' px={3}>
                                <Box pr={2} pt={1} pb={1}>
                                    <Button variant="text" onClick={props.onBack} size="large">Back</Button>
                                </Box>
                                <Box flexGrow={1}>
                                    <Fab
                                        variant="extended"
                                        color="primary"
                                        aria-label="add"
                                        className={calcClasses.button}
                                        onClick={handleApprove}
                                        disabled={loading}
                                    >
                                        <AccountBalanceWalletIcon
                                            className={calcClasses.extendedIcon}
                                        />
                                        Approve&nbsp;&nbsp;
                                    </Fab>
                                </Box>
                                {
                                    loading &&
                                    <Box pl={2} pt={1}>
                                        <CircularProgress size={30} />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <Box display="flex" justifyContent='center'>
                                <RouterLink to={localRoutes.forgotPass}>Forgot password</RouterLink>
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
        </div>
    );
}


export default ApprovalStep;
