import React from 'react';
import {differenceBy, find, findIndex, intersectionBy, unionBy} from 'lodash'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import {InvoiceLoanModel, InvoiceViewModel} from "../../../../__generated__/apiTypes";
import Typography from "@material-ui/core/Typography";
import PaymentItem from "./PaymentItem";

interface IProps {
    data: InvoiceViewModel
    selected?: string[]
    onChange: (id: string[]) => void
    readOnly?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            padding: theme.spacing(1, 2),
        },
        list: {
            width: '100%',
            minHeight: 400,
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
        }
    }),
)

function not(a: InvoiceLoanModel[], b: InvoiceLoanModel[]) {
    return differenceBy(a, b, 'loanId');
}


function intersection(a: InvoiceLoanModel[], b: InvoiceLoanModel[]) {
    return intersectionBy(a, b, 'loanId');
}

function union(a: InvoiceLoanModel[], b: InvoiceLoanModel[]) {
    return unionBy(a, b, 'loanId');
}

const PaymentsList = ({data, onChange, selected, readOnly}: IProps) => {
    const payments: InvoiceLoanModel[] = data.loans!
    const selectedPayments: InvoiceLoanModel[] = selected?.map(loanId => find(payments, {loanId}) as InvoiceLoanModel).filter(Boolean) ?? [];

    const classes = useStyles();
    const [checked, setChecked] = React.useState<InvoiceLoanModel[]>(selectedPayments);

    const handleChecked = (newChecked: InvoiceLoanModel[]) => {
        setChecked(newChecked)
        onChange(newChecked.map(it => it.loanId!))
    }

    const handleToggle = (value: InvoiceLoanModel) => () => {
        const currentIndex = findIndex(checked, {loanId: value.loanId});
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        handleChecked(newChecked);
    };

    const numberOfChecked = (items: InvoiceLoanModel[]) => intersection(checked, items).length;

    const handleToggleAll = (items: InvoiceLoanModel[]) => () => {
        if (numberOfChecked(items) === items.length) {
            handleChecked(not(checked, items));
        } else {
            handleChecked(union(checked, items));
        }
    };

    const title = 'Loans to pay';

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(payments)}
                        checked={numberOfChecked(payments) === payments.length && payments.length !== 0}
                        indeterminate={numberOfChecked(payments) !== payments.length && numberOfChecked(payments) !== 0}
                        disabled={payments.length === 0 || readOnly}
                        inputProps={{'aria-label': 'all items selected'}}
                    />
                }
                title={<Typography variant="h6">{title}</Typography>}
                subheader={`${numberOfChecked(payments)}/${payments.length} selected`}
            />
            <Divider/>
            <List className={classes.list} dense component="div" role="list">
                {payments.map((loanData: InvoiceLoanModel) => {
                    const labelId = `transfer-list-all-item-${loanData.loanId}-label`;
                    return <PaymentItem
                        key={loanData.loanId}
                        data={loanData}
                        onClick={handleToggle(loanData)}
                        isChecked={findIndex(checked, {loanId: loanData.loanId}) !== -1}
                        labelId={labelId}
                        isReadOnly={readOnly}
                    />
                })}
                <ListItem/>
            </List>
        </Card>
    );
}


export default PaymentsList;
