import React from 'react';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import {Link} from "react-router-dom";
import {localRoutes} from "../../data/constants";
import {useLoginStyles} from "./loginStyles";

interface IProps {
    message: string
}

const AuthError = ({message}: IProps) => {
    const classes = useLoginStyles();
    return (
        <Box display='flex' justifyContent='center'>
            <Box>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1">
                        Oops , Error!
                    </Typography>
                    <Box p={1}>
                        <Alert severity="error">{message}</Alert>
                        <Box display="flex" justifyContent='center' pt={2}>
                            <Link to={localRoutes.login}>Login</Link>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}


export default AuthError;
