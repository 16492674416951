import AppsIcon from "@material-ui/icons/Apps";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import EmailIcon from "@material-ui/icons/Email";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import FileIcon from "@material-ui/icons/FileCopy";
import {localRoutes} from "../data/constants";
import {appRoles} from "../data/appRoles";

export const menuItems = [
    {
        name: "Dashboard",
        route: localRoutes.dashboard,
        icon: AppsIcon
    },
    {
        icon: CreditCardOutlinedIcon,
        name: "Loans",
        route: localRoutes.loans
    },
    {
        icon: FileIcon,
        name: "Invoices",
        route: localRoutes.invoices
    },
    {
        icon: PeopleIcon,
        name: "Contacts",
        route: localRoutes.contacts
    },
    {
        name: "Messaging",
        route: localRoutes.settings,
        icon: EmailIcon,
        role: [ appRoles.Admin, appRoles.Super, appRoles.AccountManager],
        items: [
            {
                name: "Single SMS",
                route: localRoutes.singleSmsList
            },
            {
                name: "Bulk SMS",
                route: localRoutes.bulkSmsList
            },
            {
                name: "Templates",
                route: localRoutes.msgTemplates
            }
        ]
    },
    {
        name: "Admin",
        route: localRoutes.settings,
        icon: SettingsIcon,
        role: [appRoles.ClientAdmin, appRoles.Admin, appRoles.Super],
        items: [
            {
                name: "Users",
                route: localRoutes.users
            }
        ]
    }
]
