import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {Alert} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import {remoteRoutes} from "../../../data/constants";

import {getData} from "../../../utils/fetchHelpers";
import {useQuery} from "react-query";
import {InvoiceCollectionViewModel, PaymentStatus} from "../../../__generated__/apiTypes";
import {isMTN} from "../../../utils/phoneUtils";

type Props = {
    invoiceId: string
    applicantId?: string
    onClose?: () => void
    name: string
}
export const PayLoanStatus: React.FC<Props> = ({invoiceId, name, applicantId, onClose}) => {

    const [refetchInterval, setRefetchInterval] = useState<any>(1000);

    const url = `${remoteRoutes.loansRecovery}/${invoiceId}`;
    const {isLoading, error, data} = useQuery<InvoiceCollectionViewModel, any>(
        ['loansRecovery', invoiceId],
        () => getData(url),
        {
            refetchInterval,
            onSuccess: data => {
                if (data.status === PaymentStatus.Success || data.status === PaymentStatus.Failed) {
                    setRefetchInterval(false);
                }
            }
        }
    );

    if (isLoading)
        return <Box display='flex' justifyContent='center' flexDirection='column' alignItems="center" width="100%"
                    style={{height: 150}}>
            <Box py={3}>
                {
                    applicantId ?
                        <Alert severity='success'>We are processing the request. </Alert> :
                        <Alert severity='success'>Hi {name}, we are processing your request. </Alert>
                }

            </Box>
            <Box width="100%" py={1} display='flex' justifyContent='center'>
                <CircularProgress/>
            </Box>
        </Box>

    if (!data)
        return <Box display='flex' justifyContent='center' flexDirection='column' alignItems="center" width="100%"
                    style={{height: 150}}>
            <Box py={3}>
                <Alert severity='error'>{error?.message ?? 'Oops something went wrong.'}</Alert>
            </Box>
        </Box>

    if (data.status === PaymentStatus.Failed)
        return <Box display='flex' justifyContent='center' flexDirection='column' alignItems="center" width="100%"
                    style={{height: 150}}>
            <Box py={3}>
                {
                    applicantId ?
                        <Alert severity='error'>We were not able to process your request at this time. </Alert> :
                        <Alert severity='error'>Hi {name}, we were not able to process your request at this
                            time. </Alert>
                }

            </Box>
            <Box width="100%" py={1} display='flex' justifyContent='center'>
                <Button color='primary' variant='text' component={RouterLink} to="/">
                    Back to portal
                </Button>
            </Box>
        </Box>

    if (data.status === PaymentStatus.Sent || data.status === PaymentStatus.Pending) {

        return <Box display='flex' justifyContent='center' flexDirection='column' alignItems="center" width="100%"
                    style={{minHeight: 200}}>
            <Box py={3}>
                <Alert severity='success'>We are processing your request. Please go to your phone and approve the
                    transaction</Alert>
            </Box>

            {
                isMTN(data.account) &&
                <Box pb={1}>
                    <Alert severity='info'>If you do not see a pop-up on your screen.
                        <br/>
                        Dial <b>*165#</b> and select My Approvals to allow the payment.
                    </Alert>
                </Box>
            }
            <Box width="100%" py={1} display='flex' justifyContent='center'>
                <CircularProgress/>
            </Box>
        </Box>
    }


    return <Box display='flex' justifyContent='center' flexDirection='column' alignItems="center" width="100%"
                style={{height: 150}}>
        <Box py={3}>
            {
                applicantId ?
                    <Alert severity='success'>The payment was successful </Alert> :
                    <Alert severity='success'>Your payment was successful. Thank you for being
                        diligent. </Alert>
            }

        </Box>
        <Box width="100%" py={1} display='flex' justifyContent='center'>
            {
                applicantId ?
                    <Button color='primary' variant='text' onClick={onClose}>
                        Close
                    </Button> :
                    <Button color='primary' variant='text' component={RouterLink} to="/">
                        Get new loan
                    </Button>
            }
        </Box>
    </Box>
}
