import {LoanDetailedViewModel, LoanDisbursementViewModel, PublicLoanConfig} from "../../../__generated__/apiTypes";


export const loanConstants = {
    loanFetchAll: "loanFetchAll",
    loanFetchRecent: "loanFetchRecent",
    loanFetchLoading: "loanFetchLoading",
    loanFetchOne: "loanFetchOne",
    loanTriggerPayment: "loanTriggerPayment",
    loanTriggerRecover: "loanTriggerRecover",
    loanAddSettings: "loanAddSettings"
}

export interface ILoanState {
    data: LoanDetailedViewModel[]
    recent: LoanDetailedViewModel[]
    selected?: LoanDetailedViewModel
    loanSettings: PublicLoanConfig
}

const initialState: ILoanState = {
    data: [],
    recent: [],
    selected: undefined,
    loanSettings: {
        maxAmount: 1000000,
        interestRate: 10,
        minAmount: 50000,
        stepAmount: 10000
    }
}

export default function reducer(state = initialState, action: any): ILoanState {
    switch (action.type) {
        case loanConstants.loanFetchRecent: {
            const recent: LoanDetailedViewModel[] = action.payload
            return {...state, recent}
        }

        case loanConstants.loanFetchAll: {
            const data: LoanDetailedViewModel[] = action.payload
            return {...state, data}
        }


        case loanConstants.loanFetchOne: {
            const selected: LoanDetailedViewModel = action.payload
            return {...state, selected}
        }

        case loanConstants.loanTriggerPayment: {
            const disbursement: LoanDisbursementViewModel = action.payload
            if (state.selected) {
                return {...state, selected: {...state.selected, disbursement}}
            }
            return state
        }

        case loanConstants.loanTriggerRecover: {
            const selected: LoanDetailedViewModel = action.payload
            return {...state, selected}
        }

        case loanConstants.loanAddSettings: {
            const loanSettings: PublicLoanConfig = action.payload
            return {...state, loanSettings}
        }

        default: {
            return state
        }
    }
}


export function loanActionPayout(payload: LoanDisbursementViewModel) {
    return {
        type: loanConstants.loanTriggerPayment,
        payload
    }
}

export function loanActionRecover(payload: LoanDetailedViewModel) {
    return {
        type: loanConstants.loanTriggerRecover,
        payload
    }
}

export const addLoanSettings = (payload: PublicLoanConfig) => {
    return {
        type: loanConstants.loanAddSettings,
        payload
    }
}
