import React from 'react';
import {Grid} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {useCardStyles} from "../ApprovalStep";
import {useSelector} from "react-redux";
import {AppState} from "../../../data/types";
import PayLogin from "./PayLogin";
import PayTransact from "./PayTransact";
import HomeLayout from "../HomeLayout";
import Loading from "../../../components/loaders/Loading";

const PayLoan = () => {
    const {isLoadingUser, user}: any = useSelector((state: AppState) => state.core);
    const isLoggedIn = !!user;
    const classes = useCardStyles();

    if (isLoadingUser)
        return <Loading/>

    return (
        <HomeLayout>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stepper
                            activeStep={isLoggedIn ? 1 : 0}
                            orientation='horizontal'
                            className={classes.stepper}
                        >
                            <Step key="login" active={!isLoggedIn}>
                                <StepLabel>Login</StepLabel>
                            </Step>
                            <Step key="pay" active={isLoggedIn}>
                                <StepLabel>Pay now</StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                    <Grid item xs={12}>
                        {isLoggedIn ? <PayTransact/> : <PayLogin/>}
                    </Grid>
                </Grid>
            </div>
        </HomeLayout>

    );
}


export default PayLoan;
