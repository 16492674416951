import React from 'react';
import {InvoiceViewModel} from "../../../../__generated__/apiTypes";
import {Box} from "@material-ui/core";
import DetailView from "../../../../components/DetailView";
import {dataFields} from "../config";

interface IProps {
    data: InvoiceViewModel
}

const LiteInvoiceDetails = ({data}: IProps) => {
    return (
        <Box display="flex" justifyContent="center">
            <Box p={3} pt={2} width="90%">
                <DetailView data={dataFields(data)} columns={3}/>
            </Box>
        </Box>
    );
}


export default LiteInvoiceDetails;
