import React from 'react';

import IconButton from "@material-ui/core/IconButton";
import ContentCopyIcon from '@material-ui/icons/FileCopyOutlined';
import {trimGuid} from "../utils/stringHelpers";
interface IProps {
    value?:string
}

const GuidRender = ({value}: IProps) => {
    if(!value) return <span>&nbsp;</span>
    return (
        <span>
          {trimGuid(value)}
            ...
            &nbsp;
            <IconButton
                color="default"
                aria-label="Copy value"
                title="Copy value"
                component="span"
                size="small"
                onClick={() => { navigator.clipboard.writeText(value); }}
            >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </span>
    );
}


export default GuidRender;
