import * as React from 'react'
import {useField} from 'formik';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {hasValue} from "./inputHelpers";
import {styled} from "@material-ui/core/styles";

interface IProps {
    name: string
}

export const RoundedTextField = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: "24px",
        fontSize: "1.3rem"
    },
}));

const XTextInput = ({name, ...props}: TextFieldProps & IProps) => {
    const [field, meta] = useField({name});
    const error = hasValue(meta.error) ? meta.error : undefined
    const showError = Boolean(error && meta.touched)
    return (
        <TextField
            {...field}
            {...props}
            margin='normal'
            fullWidth
            error={showError}
            helperText={showError && error}
            value={field.value || ""}
            autoComplete="off"
        />
    )
}

export default XTextInput
