import {AppUser} from "./types";
import {Option} from "../components/inputs/option";

export const appRoles = {
    Super: "SUPER",
    Admin: "ADMIN",
    AccountManager: "ACCOUNT_MANAGER",

    ClientAdmin: "CLIENT_ADMIN",
    CrmManager: "CRM_MANAGER",
    LoansManager: "LOANS_MANAGER",
    AccountActivator: "ACCOUNT_ACTIVATOR",
    User: "USER",
}

export const appRolesMap: Option[] = [
    {
        id: appRoles.Admin,
        name: "System Admin"
    },
    {
        id: appRoles.AccountManager,
        name: "Key Account Manager"
    },
    {
        id: appRoles.ClientAdmin,
        name: "Client HR"
    },
    {
        id: appRoles.User,
        name: "Basic User"
    },
    {
        id: appRoles.CrmManager,
        name: "Contacts Manager"
    },
    {
        id: appRoles.LoansManager,
        name: "Loans Manager"
    },
    {
        id: appRoles.AccountActivator,
        name: "Account Activator"
    }
]

export const primaryRoles = [appRoles.Admin, appRoles.AccountManager,appRoles.AccountActivator]
export const clientRoles = [appRoles.ClientAdmin, appRoles.CrmManager, appRoles.LoansManager]
export const backOfficeRoles = [...primaryRoles, appRoles.ClientAdmin, appRoles.CrmManager, appRoles.LoansManager]
export const primaryAssignRoles = [...backOfficeRoles, appRoles.User]
export const clientAssignRoles = [...clientRoles, appRoles.User]

export const toRoleCombo = (roles: string[]) => {
    return appRolesMap.filter(it => roles.includes(it.id))
}

export const hasRole = (user: AppUser, role: string): boolean => {
    return user.roles.indexOf(role) > -1
}

export const hasAnyRole = (user: AppUser, roles: string[]): boolean => {
    return roles.some(it => hasRole(user, it))
}

export const isPrimaryAdmin = (user: AppUser) => {
    return hasAnyRole(user, [appRoles.Admin, appRoles.Super])
}

export const isPrimaryUser = (user: AppUser) => {
    return hasAnyRole(user, [...primaryRoles, appRoles.Super])
}

export const isBackOfficeUser = (user: AppUser) => {
    return hasAnyRole(user, [...backOfficeRoles, appRoles.Super])
}

export const isClientAdmin = (user: AppUser) => {
    return hasAnyRole(user, [appRoles.ClientAdmin])
}
