import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Form, Formik, FormikHelpers} from 'formik';

import * as yup from "yup";
import {post} from "../../utils/ajax";
import {localRoutes, passwordRegex, remoteRoutes} from "../../data/constants";
import XTextInput from "../../components/inputs/XTextInput";
import {useLoginStyles} from "./loginStyles";
import Box from "@material-ui/core/Box";
import {LoginResponse} from "../../data/types";
import {Alert} from "@material-ui/lab";
import {Link, useLocation} from "react-router-dom";
import AuthError from "./AuthError";

const initialValues =
    {
        "newPassword": "",
        "confirmNewPassword": ""
    }

const schema = yup.object().shape(
    {
        newPassword: yup.string()
            .matches(passwordRegex,"Password must only be numbers and letters with 6 characters or more")
            .required("Password is required"),
        confirmNewPassword: yup.string()
            .matches(passwordRegex, "Password must only be numbers and letters with 6 characters or more")
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.newPassword === value
            })
    }
);

function ForgotPassword() {
    let {search} = useLocation();

    const query = new URLSearchParams(search)
    const token = query.get("token")
    const userId = query.get("userId")

    const classes = useLoginStyles();
    const [success, setSuccess] = useState<boolean>(false)
    const onSubmit = (data: any, actions: FormikHelpers<any>) => {
        post(remoteRoutes.setNewPassword, {...data, token, userId},
            (_: LoginResponse) => {
                actions.setSubmitting(false)
                setSuccess(true)
            }, undefined, () => {
                actions.setSubmitting(false)
            })
    }
    if (!token || !userId) {
        return <AuthError message="Invalid link, please try again"/>;
    }

    return (
        <Box display='flex' justifyContent='center'>
            <Box>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1">
                        Set password
                    </Typography>
                    {
                        success ?
                            <Box p={1}>
                                <Alert>You have successfully reset your password</Alert>
                                <Box display="flex" justifyContent='center' pt={2}>
                                    <Link to={localRoutes.login}>Login</Link>
                                </Box>
                            </Box> :
                            <Formik
                                initialValues={initialValues}
                                validationSchema={schema}
                                onSubmit={onSubmit}
                            >{
                                (formState) => {
                                    return <Form className={classes.form}>

                                        <XTextInput
                                            type='password'
                                            name='newPassword'
                                            label='New Password'
                                            autoComplete="password"
                                            margin="normal"
                                            variant='outlined'
                                        />

                                        <XTextInput
                                            type='password'
                                            name='confirmNewPassword'
                                            label='ConfirmPassword'
                                            autoComplete="password"
                                            margin="normal"
                                            variant='outlined'
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            disabled={formState.isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                        <Box display="flex" justifyContent='center' pt={2}>
                                            <Link to={localRoutes.login}>Login</Link>
                                        </Box>
                                    </Form>
                                }
                            }
                            </Formik>
                    }
                </Paper>
            </Box>
        </Box>
    );
}

export default ForgotPassword


