import React, {useState} from 'react';
import {Grid, useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {localRoutes, remoteRoutes} from "../../../data/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import {inputStyle} from "../ApprovalStep";
import {useCalculatorStyles} from "../styles";
import {useDispatch} from "react-redux";
import useTheme from "@material-ui/core/styles/useTheme";
import {Alert} from "@material-ui/lab";
import {useFormik} from 'formik';
import * as yup from "yup";
import {post} from "../../../utils/ajax";
import {reqString} from "../../../data/validations";
import {doLogin} from "../../../data/redux/coreActions";
import {Link} from "react-router-dom";


const PayLogin = () => {
    const calcClasses = useCalculatorStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const formik = useFormik({
        validationSchema: yup.object().shape({
            phone: reqString,
            password: reqString
        }),
        initialValues: {
            phone: '',
            password: ''
        },
        onSubmit: (values, helpers) => {
            post(remoteRoutes.loginPhone, values, resp => {
                setMessage("Authentication successful.")
                helpers.setSubmitting(false)
                dispatch(doLogin(resp))
            }, () => {
                setError("Invalid login credentials!!")
                helpers.setSubmitting(false)
            })
        },
    });

    let inputPaddingX = 3;
    if (isSmall) {
        inputPaddingX = 1;
    }
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {
                        error && <Alert severity="error">{error}</Alert>
                    }
                    {
                        message && <Alert severity="info">{message}</Alert>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Box px={inputPaddingX}>
                        <TextField
                            className={calcClasses.textField}
                            placeholder="Phone/Email"
                            hiddenLabel
                            autoComplete="off"
                            variant='outlined'
                            name='phone'
                            fullWidth
                            size='small'
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            inputProps={
                                {
                                    style: inputStyle
                                }
                            }
                            disabled={formik.isSubmitting}
                        />
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Box px={inputPaddingX}>
                        <TextField
                            className={calcClasses.textField}
                            placeholder="Password"
                            type="password"
                            name='password'
                            fullWidth
                            size='small'
                            autoComplete="off"
                            variant='outlined'
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            inputProps={
                                {
                                    style: inputStyle
                                }
                            }
                            disabled={formik.isSubmitting}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    {
                        formik.isSubmitting &&
                        <Box width="100%" py={2} display='flex' justifyContent='flex-end'>
                            <CircularProgress/>
                        </Box>
                    }
                </Grid>
                <Grid item xs={10}>
                    <Box width="100%" py={2} pr={3} display='flex' justifyContent='flex-end'>
                        <Fab
                            variant="extended"
                            color="primary"
                            aria-label="add"
                            className={calcClasses.button}
                            onClick={formik.submitForm}
                            disabled={formik.isSubmitting}
                        >
                            &nbsp;&nbsp;Login&nbsp;&nbsp;
                        </Fab>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent='center'>
                        <Link to={localRoutes.forgotPass}>Forgot password</Link>
                    </Box>
                </Grid>

            </Grid>
        </form>
    );
}


export default PayLogin;
