import React, {lazy, Suspense} from 'react';
import {Link, Route, Switch, useLocation} from 'react-router-dom'
import {localRoutes} from "../data/constants";

import LayoutLoading from "./auth/LayoutLoading";
import Home from "./home/Home";
import RequestForm from "./loans/invoices/recover-request/RequestForm";
import AgingReport from "./loans/aging-report/AgingReport";
import Layout from "../layout/Layout";
import {Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {AppState} from "../data/types";
import {isPrimaryUser} from "../data/appRoles";
import {isSuper} from "../utils/userHelper";

const Dashboard = lazy(() => import( "./dashboard/Dashboard"));
const PayLoan = lazy(() => import( "./home/payback/PayLoan"));
const Contacts = lazy(() => import( "./crm/list/ContactsList"));
const ContactDetails = lazy(() => import( "./crm/details/ContactDetails"));
const UsersList = lazy(() => import( "./admin/users/UsersList"));

const LoanDetails = lazy(() => import( "./loans/details/Details"));
const Loans = lazy(() => import( "./loans/list/LoansList"));

const InvoiceDetails = lazy(() => import( "./loans/invoices/InvoiceDetails"));
const InvoiceList = lazy(() => import( "./loans/invoices/InvoiceList"));



const SingleSmsList = lazy(() => import( "./messaging/sms/single/SmsList"));
const SingleSmsCreate = lazy(() => import( "./messaging/sms/single/Create/Index"));
const SingleSmsDetails = lazy(() => import( "./messaging/sms/single/Details/Index"));
const TemplateList = lazy(() => import( "./messaging/templates/TemplateList"));
const TemplateDetails = lazy(() => import( "./messaging/templates/TemplateDetails"));

const BulkSmsList = lazy(() => import( "./messaging/sms/bulk/BulkSmsList"));
const BulkSmsCreate = lazy(() => import( "./messaging/sms/bulk/Create/Index"));
const BulkSmsDetails = lazy(() => import( "./messaging/sms/bulk/Details/Index"));

const ContentSwitch = () => {
    const user = useSelector((state: AppState) => state.core.user!)
    return <Suspense fallback={<LayoutLoading/>}>
        <Switch>
            <Route exact path="/" component={Home}/>
            {
                isPrimaryUser(user) ?
                    <Route path={localRoutes.dashboard} component={AgingReport}/> :
                    <Route path={localRoutes.dashboard} component={Loans}/>
            }

            <Route path={localRoutes.contactsDetails} component={ContactDetails}/>
            <Route path={localRoutes.contacts} component={Contacts}/>
            <Route path={localRoutes.users} component={UsersList}/>
            <Route path={localRoutes.loansDetails} component={LoanDetails}/>
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.loansAging} component={AgingReport}/>
            }
            {
                isSuper(user) &&
                <Route path={localRoutes.dashboardHidden} component={Dashboard}/>
            }

            <Route path={localRoutes.loans} component={Loans}/>
            <Route path={localRoutes.invoicesRecoverView} component={RequestForm}/>
            <Route path={localRoutes.invoicesDetails} component={InvoiceDetails}/>
            <Route path={localRoutes.invoices} component={InvoiceList}/>
            <Route path={localRoutes.pay} component={PayLoan}/>

            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.singleSmsCreate} component={SingleSmsCreate}/>
            }
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.singleSmsDetails} component={SingleSmsDetails}/>
            }
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.singleSmsList} component={SingleSmsList}/>
            }
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.msgTemplatesCreate} component={TemplateDetails}/>
            }
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.msgTemplatesDetails} component={TemplateDetails}/>
            }
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.msgTemplates} component={TemplateList}/>
            }

            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.bulkSmsCreate} component={BulkSmsCreate}/>
            }
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.bulkSmsDetails} component={BulkSmsDetails}/>
            }
            {
                isPrimaryUser(user) &&
                <Route path={localRoutes.bulkSmsList} component={BulkSmsList}/>
            }
            <Route path="*">
                <NoMatch/>
            </Route>
        </Switch>
    </Suspense>
}

export function NoMatch() {
    let location = useLocation();
    return (
        <Layout title="Oops nothing here">
            <Typography variant="h3">
                No match for <code>{location.pathname}</code>&nbsp;&nbsp;
                <Link to="/">&nbsp;Take me home</Link>
            </Typography>
        </Layout>
    );
}


export default ContentSwitch
