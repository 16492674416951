import React from "react";
import {InvoiceViewModel} from "../../../__generated__/apiTypes";
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import {printMoney} from "../../../utils/numberHelpers";
import {printDate} from "../../../utils/dateHelpers";
import {renderInvoiceNumber} from "../../loans/invoices/config";

type Props = {
    data: InvoiceViewModel
    onSelect: (data: InvoiceViewModel | null) => void
    selected: string | null
    canToggle?: boolean
}
const InvoiceView: React.FC<Props> = ({data, onSelect, selected, canToggle}) => {
    const handleChange = (event: any) => {
        if (event.target.checked) {
            onSelect(data);
        } else {
            onSelect(null);
        }
    };
    return <Box>
        <Grid container spacing={1}>
            {
                canToggle && (
                    <Grid item xs={1}>
                        <Checkbox
                            checked={selected === data.id}
                            onChange={handleChange}
                            inputProps={{'aria-label': 'select invoice'}}
                        />
                    </Grid>
                )
            }

            <Grid item xs={11}>
                <Box px={2} pb={1}>
                    <Typography
                        component="div"
                        variant="h5"
                        color="textPrimary"
                    >
                        Invoice: {renderInvoiceNumber(data.invoiceNumber!)}
                    </Typography>
                    <Typography
                        component="div"
                        variant="body1"
                        color="textSecondary"
                    >
                        Amount: <b>{printMoney(data.amount)}</b>
                    </Typography>
                </Box>
                <Box px={2}>
                    <Typography
                        component="div"
                        variant="caption"
                        color="textSecondary"
                    >
                        Due date: &nbsp;{printDate(data.dueDate)}
                    </Typography>
                </Box>

            </Grid>
        </Grid>
    </Box>
}

export default InvoiceView;