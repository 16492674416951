import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import {sumBy} from 'lodash'
import StepLabel from '@material-ui/core/StepLabel';
import Layout from "../../../../layout/Layout";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import UploadReceipt from "./UploadReceipt";
import SelectPayments from "./SelectPayments";
import Submit from "./Submit";
import Toast from "../../../../utils/Toast";
import {postFile} from "../../../../utils/ajax";
import {localRoutes, remoteRoutes} from "../../../../data/constants";
import {useHistory, useParams} from "react-router";
import {useQuery} from "react-query";
import {InvoiceViewModel} from "../../../../__generated__/apiTypes";
import {getData} from "../../../../utils/fetchHelpers";
import Loading from "../../../../components/loaders/Loading";
import {Alert} from "@material-ui/lab";
import {hasNoValue} from "../../../../components/inputs/inputHelpers";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

function getSteps() {
    return ['Upload receipt', 'Select payments', 'Approve'];
}


export default function RequestForm() {
    const classes = useStyles();
    const history = useHistory()
    const {invoiceId} = useParams<any>();
    const [activeStep, setActiveStep] = React.useState(0);
    const [files, setFiles] = useState<FileList | null>(null)
    const [payments, setPayments] = useState<string[]>([])
    const [comment, setComment] = useState<string | null>(null)

    const {isLoading, error, data} = useQuery<InvoiceViewModel, any>(
        ['invoiceDetails', invoiceId],
        () => getData(`${remoteRoutes.invoices}/${invoiceId}`)
    );


    const [loading, setLoading] = useState<boolean>(false)

    function handleSubmit() {
        if (files === null) {
            Toast.info("Please select a file")
            return
        }

        if (hasNoValue(payments)) {
            Toast.info("Please select payments")
            return
        }
        setLoading(true)
        let formData = new FormData()
        formData.append('file', files[0])
        formData.append('id', invoiceId)
        payments.forEach(it => {
            formData.append('clearedLoans', it)
        })
        postFile(remoteRoutes.invoicesPay, formData, () => {
            Toast.info("Submitted")
            history.push(`${localRoutes.invoices}/${invoiceId}`)
        }, undefined, () => {
            setLoading(false)
        })
    }

    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    if (isLoading)
        return <Layout><Loading/></Layout>

    if (error || !data)
        return <Layout>
            <Alert severity='success'>Oops something went wrong</Alert>
        </Layout>

    const selectedLoansList = data.loans?.filter(it=>payments.includes(it.loanId!))
    const selectedSum = sumBy(selectedLoansList,'totalAmount')

    return (
        <Layout>
            <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Paper>
                    <Grid container style={{padding: 8}} spacing={2}>
                        <Grid item xs={12}>
                            {activeStep === 0 && (
                                <UploadReceipt
                                    data={data}
                                    onChange={setFiles}
                                    value={files}
                                    onNext={handleNext}
                                    loading={loading}
                                />
                            )}
                            {activeStep === 1 && (
                                <SelectPayments
                                    file={files ? files[0] : ""}
                                    data={data}
                                    onChange={setPayments}
                                    value={payments}
                                    onBack={handleBack}
                                    onNext={handleNext}
                                    loading={loading}
                                />
                            )}

                            {activeStep === 2 && (
                                <Submit
                                    data={data}
                                    onChange={setComment}
                                    value={comment}
                                    onBack={handleBack}
                                    onSubmit={handleSubmit}
                                    clearedPayments={payments.length}
                                    clearedAmount={selectedSum}
                                    totalPayments={data.loans!.length}
                                    loading={loading}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Layout>
    );
}
