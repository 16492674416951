import React from 'react';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import GuidRender from "../../../../components/GuidRender";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import {printDate} from "../../../../utils/dateHelpers";

import Divider from "@material-ui/core/Divider";
import {InvoiceLoanModel} from "../../../../__generated__/apiTypes";
import ContactLink from "../../../../components/links/ContactLink";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(8),
        },
    }),
);


interface IProps {
    data: InvoiceLoanModel
    isChecked: boolean
    isReadOnly?: boolean
    labelId: string
    onClick: () => void
}

const PaymentItem = ({data, isChecked, onClick, labelId, isReadOnly}: IProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClick = (e: React.SyntheticEvent) => {
        setOpen(!open);
        e.stopPropagation()
    };
    return (
        <>
            <ListItem key={data.loanId} role="listitem" button onClick={isReadOnly?undefined:onClick}>
                <ListItemIcon>
                    <Checkbox
                        disabled={isReadOnly}
                        checked={isChecked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </ListItemIcon>
                <ListItemText
                    id={labelId}
                    primary={
                        <ContactLink id={data.applicantId!} name={data.applicantName!}/>
                    }
                    secondary={<>
                        {printDate(data.createdAt)}&nbsp;&nbsp;<GuidRender value={data.loanId}/>
                    </>}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="expand" onClick={handleClick}>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider/>
                <List component="div" disablePadding>
                    {
                        data.repayments!.map(it => {
                            return <ListItem button className={classes.nested} key={it.id}>
                                <ListItemText
                                    primary={it.totalAmount}
                                    secondary={printDate(it.createdAt)}
                                />
                            </ListItem>
                        })
                    }

                </List>
            </Collapse>
        </>

    );
}


export default PaymentItem;
