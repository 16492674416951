import React from "react";
import {LoanListViewModel} from "../../../__generated__/apiTypes";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {printMoney} from "../../../utils/numberHelpers";
import {renderInvoiceNumber} from "../../loans/invoices/config";
import {renderSubStatus} from "../../loans/list/config";

type Props = {
    data: LoanListViewModel
}
const LoanView: React.FC<Props> = ({data}) => {

    return <Box pb={2} >
        <Box px={2} pb={1}>
            <Typography
                component="div"
                variant="h5"
                color="textPrimary"
                noWrap
            >
                Loan: {renderInvoiceNumber(data.loanNumber!)}
            </Typography>
            <Typography
                component="div"
                variant="body1"
                color="textSecondary"
                noWrap
            >
                Amount: <b>{printMoney(data.amount)}</b>
            </Typography>
        </Box>
        <Box px={2}>
            <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
            >
                Status: &nbsp;{renderSubStatus(data.subStatus!)}
            </Typography>
        </Box>
    </Box>
}

export default LoanView;
