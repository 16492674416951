import React from 'react';
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {hasNoValue} from "../../../../components/inputs/inputHelpers";
import {InvoiceViewModel} from "../../../../__generated__/apiTypes";
import LiteInvoiceDetails from "./LiteInvoiceDetails";


interface IProps {
    onChange: (files: FileList) => void
    value: FileList | null
    loading?: boolean
    onNext: () => void
    data: InvoiceViewModel
}

const InvoiceRecover = ({onChange, value, loading, onNext, data}: IProps) => {

    function handleFileChange(e: any) {
        const files: FileList = e.target.files
        onChange(files)
    }

    return (
        <Box px={16} pb={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LiteInvoiceDetails data={data}/>
                </Grid>
                <Grid item xs={12}>

                    <Box px={3} display="flex" justifyContent="center">
                        <TextField
                            type='file'
                            variant="outlined"
                            color="secondary"
                            onChange={handleFileChange}
                            inputProps={{
                                accept: "image/jpeg,image/gif,image/png,application/pdf"
                            }}
                        />
                        <Box pl={4} pt={1}>
                            <Button
                                disabled={loading || hasNoValue(value)}
                                onClick={onNext}
                                variant='outlined'
                                color='primary'
                            >Next</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default InvoiceRecover;
