import React, {FC, useState} from "react";
import {filter as _filter} from 'lodash'
import Layout from "../../../layout/Layout";
import XTable from "../../../components/table/XTable";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {columns} from "./config";
import {remoteRoutes} from "../../../data/constants";
import {useQuery} from "react-query";
import {searchData} from "../../../utils/fetchHelpers";
import Loading from "../../../components/loaders/Loading";
import ErrorDisplay from "../../../components/ErrorDisplay";
import Filter from "./Filter";
import ReportSummary from "./ReportSummary";
import {ICsvColumn, jsArray2CSV} from "../../../utils/csvHelpers";
import Toast from "../../../utils/Toast";
import Information from "../../dashboard/Information";
import {LoanAgingSummaryModel, LoanAgingModel} from "../../../__generated__/apiTypes";


type Props = {
    error: any
    isLoading: boolean
    data?: LoanAgingModel[]
};

const ListView: FC<Props> = ({error, isLoading, data}) => {

    if (isLoading) return <Loading/>;
    if (!data) return <ErrorDisplay text={error?.message ?? 'Failed to load data, check connectivity.'}/>;
    return (
        <XTable
            headCells={columns}
            data={data!}
            initialRowsPerPage={10}
            initialSortBy="createdAt"
            initialOrder="desc"
        />
    );
};


const AgingReport = () => {
    const [filter, setFilter] = useState<any>({});
    const [summaryFilter, setSummaryFilter] = useState<any>({});

    const {isLoading, error, data: {loanData, ...summary} = {}} = useQuery<LoanAgingSummaryModel, any>(
        ['AgingReportSummary', filter],
        () => searchData(remoteRoutes.loansAgingReportSummary, filter)
    );

    const handleFilter = (newFilter: any) => {
        setFilter((prevFilter: any) => ({...prevFilter, ...newFilter}))
    }

    const handleSummaryFilter = (newFilter: any) => {
        setSummaryFilter(newFilter)
    }

    function handleExport() {
        const colsRaw: ICsvColumn[] = columns.map(it => ({dataKey: it.name, title: it.label}))
        if (loanData)
            jsArray2CSV(loanData, colsRaw)
        else Toast.warn("There is no data to export")
    }

    function fnFilter(it: any) {
        return it.category === 'A' || it.category === 'B'
    }

    let filtered = loanData ? _filter(loanData, summaryFilter.category === 'A' ? fnFilter : summaryFilter) : undefined;

    const reportText = "New loans will show up after 5 minutes";

    return (
        <Layout>
            <Box p={1}>
                <Box pb={2}>
                    <Typography variant='h5'>Ageing report &nbsp;<Information text={reportText}/></Typography>

                </Box>
                <Box pb={2}>
                    <ReportSummary
                        onFilter={handleSummaryFilter}
                        filter={summaryFilter}
                        data={summary}
                        loading={isLoading}
                        error={error}
                    />
                </Box>
                <Box pb={2}>
                    <Filter onFilter={handleFilter} loading={false} onExport={handleExport}/>
                </Box>
                <ListView data={filtered} error={error} isLoading={isLoading}/>
            </Box>
        </Layout>
    );
}

export default AgingReport
