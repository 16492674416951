import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";

import {Alert} from "@material-ui/lab";
import Loading from "../../components/loaders/Loading";
import PdfViewer from "../../components/PdfViewer";
import {XDocumentViewModel} from "../../__generated__/apiTypes";

const useStyles = makeStyles(() =>
    createStyles({
        fullBody: {
            height: "100%",
            width: '100%',
        },
        imgHolder: {
            height: "100%",
            width: '100%',
            textAlign: 'center'
        },
        img: {
            maxWidth: "100%",
            height: 'auto',
            maxHeight: "100%",
            margin: '0 auto'
        },
    }),
);

interface IProps {
    url: string
    loading: boolean
    xDoc: XDocumentViewModel | null
}

const DocView = ({url, loading, xDoc}: IProps) => {
    const classes = useStyles();
    return (
        <div style={{width:595,maxWidth: '100%', height: 842, maxHeight: '100%'}}>
            {
                loading ?
                    <Loading/> :
                    (url) ?
                        <div className={classes.fullBody}>
                            {
                                xDoc!.contentType!.indexOf('image') > -1 ? (
                                    <div className={classes.imgHolder}>
                                        <img
                                            className={classes.img}
                                            src={url}
                                            alt=''
                                        />
                                    </div>
                                ) : (
                                    <PdfViewer data={url}/>
                                )
                            }
                        </div>
                        :
                        <Box p={3}>
                            <Alert severity="error">Failed to load documents</Alert>
                        </Box>
            }

        </div>
    );
}


export default DocView;
