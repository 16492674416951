import {cleanFormData, getToken, IClientError} from './ajax';
import {AUTH_KEY_TOKEN, AUTH_KEY_USER} from '../data/constants';
import {hasValue} from '../components/inputs/inputHelpers';


export function serialize(obj: any, prefix?: string) {
    const str: any = [];
    Object.entries(obj).filter((it) => {
        const [, v] = it;
        return hasValue(v);
    }).forEach((e: any) => {
        const [p, v] = e;
        const k = prefix ? `${prefix}[${p}]` : p;
        str.push((v !== null && typeof v === 'object')
            ? serialize(v, k)
            : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    });
    return str.join('&');
}


const getValidationError = (data: any): string | null => {
    const error: IClientError = data;
    if (hasValue(error.title) && error.title.indexOf('validation errors') > -1) {
        let {title} = error;
        Object.entries(error.errors).forEach((it) => {
            const [, value] = it;
            title = `${title}\n${value}`;
        });
        return title;
    }
    return null;
};

export const handleError = async (res: Response): Promise<any> => {
    const defaultMessage = 'Failed to load data, check connectivity.';

    if (res.status === 403) {
        throw Error('You don\'t have permission for this page!');
    }
    if (res.status === 401) {
        localStorage.removeItem(AUTH_KEY_TOKEN);
        localStorage.removeItem(AUTH_KEY_USER);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        throw Error('You need to login to see this page!');
    }
    if (res.status === 400) {
        const validationError: any = getValidationError(res);
        if (hasValue(validationError)) {
            throw Error(validationError);
        }
        const {message, errors = []} = await res.json();
        let msg = `${message}\n`;

        errors.forEach((it: any) => {
            const error = Object.values(it)[0];
            msg += (`${error}\n`);
        });
        throw Error(msg || defaultMessage);
    }
    throw Error("Can't reach server, Check connectivity!");
};

export async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            Accept: 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        return handleError(response);
    }
    return response.json();
}

export async function putData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            Accept: 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        return handleError(response);
    }
    return response.json();
}

export async function searchData(url = '', data = {}) {
    const response = await fetch(`${url}?${serialize(cleanFormData(data))}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            Accept: 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    if (!response.ok) {
        return handleError(response);
    }
    return response.json();
}

export async function getData(url: string) {
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            Accept: 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    if (!response.ok) {
        return handleError(response);
    }
    return response.json();
}

export async function getBlob(url: string) {
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            Accept: 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    if (!response.ok) {
        return handleError(response);
    }
    return response.blob().then((blobData) => URL.createObjectURL(blobData));
}

export async function deleteData(url = '') {
    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            Accept: 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    if (!response.ok) {
        return handleError(response);
    }
    return response.json();
}
