import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Box} from "@material-ui/core";

type Props = {
    mt?: number
}
const Loading: React.FC<Props> = ({mt = 12}) => {
    return (
        <Box width='100%' display='flex' alignContent='center' justifyContent='center' mt={mt}>
            <CircularProgress/>
        </Box>
    );
}
export default Loading;
