import React from 'react';
import Box from '@material-ui/core/Box';
import {Alert} from "@material-ui/lab";

interface IProps {
    text: any
}

const ErrorDisplay = ({text}: IProps) => (
    <Box
        display="flex" justifyContent="center" p={1} m={1}

    >
        <Alert severity="error">{text}</Alert>
    </Box>
);
export default ErrorDisplay;
