import * as React from "react";
import {useState} from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Box} from "@material-ui/core";
import {PRemoteSelect} from "../../../components/plain-inputs/PRemoteSelect";
import {remoteRoutes} from "../../../data/constants";
import {useSelector} from "react-redux";
import {AppState} from "../../../data/types";
import {isPrimaryUser} from "../../../data/appRoles";
import {cleanComboValue} from "../../../utils/dataHelpers";
import {cleanFormData} from "../../../utils/ajax";

interface IProps {
    onFilter: (data: any) => any
    onExport: () => any
    loading: boolean
}

const Filter = ({onFilter, loading, onExport}: IProps) => {
    const user = useSelector((state: AppState) => state.core.user!)
    const [data, setData] = useState<any>({
        organizationId: null,
        accountManagerId: null
    })

    function submitForm(values: any) {
        onFilter(cleanFormData(values))
    }

    const handleComboChange = (name: string) => (value: any) => {
        const newData = {...data, [name]: value}
        const filterData = {...data, [name]: cleanComboValue(value)}
        setData(newData)
        submitForm(filterData)
    }

    return <form>
        <Grid spacing={2} container>
            {
                isPrimaryUser(user) &&
                <Grid item xs={12} md={4}>
                    <PRemoteSelect
                        name="organizationId"
                        value={data['organizationId']}
                        onChange={handleComboChange('organizationId')}
                        label="Company"
                        remote={remoteRoutes.contactsCompany}
                        variant='outlined'
                        margin='none'
                        textFieldProps={
                            {size: "small"}
                        }
                        parser={it => it}
                        filter={{}}
                    />
                </Grid>
            }
            {
                isPrimaryUser(user) &&
                <Grid item xs={12} md={4}>
                    <PRemoteSelect
                        name="accountManagerId"
                        value={data['accountManagerId'] || null}
                        onChange={handleComboChange('accountManagerId')}
                        label="Account Manager"
                        variant='outlined'
                        size='small'
                        remote={remoteRoutes.accountManagersCombo}
                        fullWidth
                        margin='none'
                        searchOnline={false}
                    />
                </Grid>
            }
            <Grid item xs={12} md={4}>
                <Box display="flex" flexDirection="row-reverse">
                    <Button
                        disabled={loading}
                        variant="outlined"
                        color="primary"
                        onClick={onExport}>Export</Button>
                </Box>
            </Grid>
        </Grid>
    </form>

}

export default Filter
