import {blueColor, defaultColor, errorColor, successColor, warningColor} from "../../../theme/custom-colors";
import Chip from "@material-ui/core/Chip";
import React from "react";

import {XHeadCell} from "../../../components/table/XTableHead";
import InvoiceLink from "../../../components/links/InvoiceLink";
import ContactLink from "../../../components/links/ContactLink";
import {printDate, printDateTime} from "../../../utils/dateHelpers";
import {IRec} from "../../../components/DetailView";
import {printMoney} from "../../../utils/numberHelpers";
import {InvoiceStatus, InvoiceViewModel, PaymentStatus} from "../../../__generated__/apiTypes";
import GuidRender from "../../../components/GuidRender";
import {hasValue} from "../../../components/inputs/inputHelpers";

export const renderInvoiceStatus = (value: InvoiceStatus) => {
    let color: any = warningColor
    switch (value) {
        case InvoiceStatus.Paid:
            color = successColor
            break
        case InvoiceStatus.PartiallyPaid:
            color = blueColor
            break
        case InvoiceStatus.Posted:
            color = errorColor
            break
        case InvoiceStatus.Generated:
            color = warningColor
            break
        case InvoiceStatus.Void:
            color = defaultColor
            break
    }

    return <Chip
        color='primary'
        variant='default'
        size='small'
        label={value}
        style={{padding: 0, height: 18, backgroundColor: color, marginBottom: 2}}
    />
}

export const renderInvoiceNumber = (num: number) => '#' + `${num}`.padStart(4, '0')


export const columns: XHeadCell[] = [
    {
        name: 'invoiceNumber',
        label: 'No.',
        render: (data, rec) => <InvoiceLink name={renderInvoiceNumber(data)} id={rec.id}/>,
        cellProps: {
            width: 50
        }
    },
    {
        name: 'contact.name',
        label: 'Client',
        render: (data, rec) => <ContactLink name={data} id={rec.contactId}/>,
        cellProps: {
            style: {whiteSpace: 'wrap'}
        }
    },
    {
        name: 'status',
        label: 'Status',
        render: renderInvoiceStatus
    },
    {
        name: 'issueDate',
        label: 'Issue Date',
        render: printDateTime
    },
    {
        name: 'dueDate',
        label: 'Due Date',
        render: printDateTime
    }
]

export const dataFields = (data: InvoiceViewModel): IRec[] => {
    const recs= [
        {
            label: 'Created at',
            value: printDate(data.createdAt)
        },
        {
            label: 'Issue date',
            value: printDate(data.issueDate)
        },
        {
            label: 'Due date',
            value: printDate(data.dueDate)
        },
        {
            label: 'Invoice No',
            value: `${data.invoiceNumber}`.padStart(4, "0")
        }
        ,
        {
            label: 'Issued to',
            value: <ContactLink name={data.contact!.name!} id={data.contact!.id!}/>
        },
        {
            label: 'Amount',
            value: printMoney(data.amount)
        },
        {
            label: 'Comments',
            value: data.comments
        },

    ]

    if(hasValue(data.clearedBy)){
        recs.push({
            label: 'Receipt by',
            value: <ContactLink name={data.clearedByData?.name??''} id={data.clearedByData?.id??''}/>
        })
    }

    if(hasValue(data.approvedBy1)){
        recs.push({
            label: 'First approver',
            value: <ContactLink name={data.approvedBy1Data?.name??''} id={data.approvedBy1Data?.id??''}/>
        })
    }

    if(hasValue(data.approvedBy)){
        recs.push({
            label: 'Second approver',
            value: <ContactLink name={data.approvedByData?.name??''} id={data.approvedByData?.id??''}/>
        })
    }

    return recs;
}


export const renderPaymentStatus = (value: PaymentStatus) => {
    let color: any = successColor
    switch (value) {
        case PaymentStatus.Success:
            color = successColor
            break
        case PaymentStatus.Failed:
            color = errorColor
            break
        case PaymentStatus.Pending:
        case PaymentStatus.Sent:
            color = warningColor
            break
    }

    return <Chip
        color='primary'
        variant='default'
        size='small'
        label={value}
        style={{padding: 0, height: 18, backgroundColor: color, marginBottom: 2}}
    />
}

export const dataCollectionFields = (model: InvoiceViewModel): IRec[] => {
    const data = model.collection!
    return [
        {
            label: 'Created at',
            value: printDate(data.createdAt)
        },
        {
            label: 'Account',
            value: data.account
        },
        {
            label: 'Amount',
            value: printMoney(data.amount)
        },
        {
            label: 'Status',
            value: renderPaymentStatus(data.status!)
        },
        {
            label: 'Vendor Txn Id',
            value: <GuidRender value={data.vendorTransactionId!}/>
        }
        ,
        {
            label: 'Comments',
            value: data.message
        }
    ]
}
