import React from 'react';
import LoanCalculator from "./LoanCalculator";
import ApprovalStep from "./ApprovalStep";
import HomeLayout from "./HomeLayout";
import Box from "@material-ui/core/Box";


const Home = () => {
    const [amount, setAmount] = React.useState<number>(0);
    const [approval, setApproval] = React.useState<boolean>(false);

    function handleGetNow(amount: number) {
        setAmount(amount)
        setApproval(true)
    }

    return (
        <HomeLayout>
            {
                approval
                    ? <ApprovalStep amount={amount} onBack={() => setApproval(false)}/>
                    : <Box width="100%">
                        <LoanCalculator onApply={handleGetNow}/>
                    </Box>
            }
        </HomeLayout>
    );
};


export default Home;
