import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from '@material-ui/core/TextField';
import DetailView, {IRec} from "../../../../components/DetailView";
import {printMoney, printNumber} from "../../../../utils/numberHelpers";
import {InvoiceViewModel} from "../../../../__generated__/apiTypes";
import LiteInvoiceDetails from "./LiteInvoiceDetails";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {hasNoValue} from "../../../../components/inputs/inputHelpers";

interface IProps {
    onSubmit: () => void
    onBack: () => void
    onChange: (remarks: string) => void
    value: string | null
    loading?: boolean
    data: InvoiceViewModel
    clearedPayments: number
    totalPayments: number
    clearedAmount: number
}



export const summaryFields = (data: any): IRec[] => {
    return [
        {
            label: 'Loans to pay',
            value: printNumber(data.clearedPayments)
        }
        ,
        {
            label: 'Amount to pay',
            value: printMoney(data.clearedAmount)
        }
    ]
}


const Submit = ({onSubmit, onBack, onChange, loading, data, value, clearedPayments, totalPayments,clearedAmount}: IProps) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <LiteInvoiceDetails data={data}/>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Box py={2} display="flex" justifyContent="center" width="100%">
                    <Typography variant='h6'>Payment summary</Typography>
                </Box>
                <Box py={2} display="flex" justifyContent="center" width="100%">
                    <Box>
                        <DetailView data={summaryFields({...data,clearedPayments, totalPayments, clearedAmount})}/>
                    </Box>
                </Box>
                <Box px={3} pb={2} display="flex" justifyContent="center" width="100%">
                    <Box width="50%">
                        <TextField
                            id="outlined-basic"
                            label="Remarks"
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth
                            onChange={e => onChange(e.target.value)}
                            value={value}
                        />
                    </Box>
                </Box>
                <Box px={3} display="flex" justifyContent="center" width="100%">
                    <Box width="50%" display='flex' flexDirection="row-reverse">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            disabled={loading || hasNoValue(value)}
                        >
                            Submit
                        </Button>
                        <Button onClick={onBack}>
                            Back
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Submit;
