import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import {ToastContainer} from "react-toastify";

import {QueryClient, QueryClientProvider} from "react-query";
import store from "./data/redux/store";
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import {Provider} from "react-redux";

const queryClient = new QueryClient()


ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <ToastContainer/>
            <CssBaseline/>
            <QueryClientProvider client={queryClient}>
                <App/>
            </QueryClientProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

const updateServiceWorker = (registration:ServiceWorkerRegistration) => {
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e:any) => {
            if (e.target.state === 'activated') {
                window.location.reload();
            }
        });
    }
};

serviceWorker.register({
    onUpdate: registration => {
        if (window.confirm("New content available, update now?")) {
            updateServiceWorker(registration)
        }
    }
});
