import React, {useCallback, useEffect, useState} from 'react';
import {Grid, useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {APP_NAME_SHORT, remoteRoutes, termsLink} from "../../../data/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import {inputStyle} from "../ApprovalStep";
import {useCalculatorStyles} from "../styles";
import {useSelector} from "react-redux";
import useTheme from "@material-ui/core/styles/useTheme";
import {Alert} from "@material-ui/lab";
import {useFormik} from 'formik';
import * as yup from "yup";
import {post, search} from "../../../utils/ajax";
import {checkedBool, reqString} from "../../../data/validations";
import Loading from "../../../components/loaders/Loading";
import {AppState} from "../../../data/types";
import Button from "@material-ui/core/Button";
import {hasValue} from "../../../components/inputs/inputHelpers";
import {PayLoanStatus} from "./PayLoanStatus";
import InvoiceView from "./InvoiceView";
import {InvoiceCollectionViewModel, InvoiceViewModel, LoanBalanceViewModel} from "../../../__generated__/apiTypes";
import LoanView from "./LoanView";
import Toast from "../../../utils/Toast";

type Props = {
    applicantId?: string
    onClose?: () => void
}
const PayTransact: React.FC<Props> = ({applicantId, onClose}) => {
    const calcClasses = useCalculatorStyles();
    const theme = useTheme();
    const {user}: any = useSelector((state: AppState) => state.core);
    const [fetchingBalance, setFetchingBalance] = useState<boolean>(true);
    const [selectedInvoice, setSelectedInvoice] = useState<InvoiceViewModel | null>(null);
    const [collection, setCollection] = useState<InvoiceCollectionViewModel | null>(null);
    const [loanBalance, setLoanBalance] = useState<LoanBalanceViewModel | null>(null);
    const [error, setError] = useState<string | null>(null);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {setFieldValue, ...formik} = useFormik({
        validationSchema: yup.object().shape({
            phone: reqString,
            invoiceId: reqString,

            terms: checkedBool
        }),
        initialValues: {
            phone: '',
            invoiceId: '',
            terms: !!applicantId,
            amount: '',
        },
        onSubmit: (values, helpers) => {

            const amount = parseInt(values.amount)

            if (amount < 500) {
                Toast.warn("Invalid amount, should be 500/= or more.")
                helpers.setSubmitting(false)
                return;
            }

            if (amount > selectedInvoice?.amount!) {
                Toast.warn(`Oops, please enter an amount less than or equal to ${selectedInvoice?.amount}.`)
                helpers.setSubmitting(false)
                return;
            }

            post(remoteRoutes.loansRecovery, values, resp => {
                setCollection(resp)
            }, () => {
                setError("Oops something went wrong!")
            }, () => {
                helpers.setSubmitting(false)
            })
        },
    });

    const fetchBalance = useCallback(() => {
        setFetchingBalance(true)
        search(remoteRoutes.loansRecovery, {applicantId}, resp => {
            setLoanBalance(resp)
            const [inv] = resp.invoices || []
            setFieldValue("phone", resp.defaultPhone)
            setFieldValue("amount", inv.amount)
            setFieldValue("invoiceId", inv?.id)
            setSelectedInvoice(inv)
        }, undefined, () => {
            setFetchingBalance(false)
        })
    }, [applicantId, setFieldValue])

    useEffect(() => {
        fetchBalance()
    }, [fetchBalance, setFieldValue])

    const onInvoiceSelected = (data: InvoiceViewModel | null) => {

        setFieldValue("invoiceId", data?.id)
        setFieldValue("amount", data?.amount)
        setSelectedInvoice(data)
    }

    let inputPaddingX = 3;
    if (isSmall) {
        inputPaddingX = 1;
    }

    if (fetchingBalance)
        return <Box display='flex' justifyContent='center' flexDirection='column' alignItems="center" width="100%"
                    style={{height: 150}}>
            <Box>
                <Typography variant='h5' color='primary'>Fetching loan balance ...</Typography>
            </Box>
            <Box>
                <Loading mt={3}/>
            </Box>
        </Box>

    if (!loanBalance)
        return <Box display='flex' justifyContent='center' flexDirection='column' alignItems="center" width="100%"
                    style={{height: 150}}>
            <Box py={3}>
                <Alert severity='error'>Oops something went wrong!</Alert>
            </Box>
        </Box>

    function handleCheckAgain() {
        setFetchingBalance(true)
        setTimeout(() => {
            fetchBalance()
        }, 1000)
    }

    const hasPendingLoans = hasValue(loanBalance.pendingLoans);

    if (loanBalance.totalAmount === 0)
        return <Grid container spacing={3}>
            <Grid item xs={12}>
                {
                    hasPendingLoans ?
                        <Box display='flex' justifyContent='center' width="100%">
                            <Box px={inputPaddingX}>
                                {
                                    loanBalance.pendingLoans?.map((it) => (
                                        <LoanView
                                            key={it.id}
                                            data={it}
                                        />
                                    ))
                                }
                            </Box>
                        </Box> :
                        <Box display='flex' justifyContent='center' py={1}>
                            {
                                applicantId ?
                                    <Alert severity='info'>We didn't find any outstanding loans.</Alert> :
                                    <Alert severity='info'>Hi {user.fullName}, you do not have any outstanding
                                        loans.</Alert>
                            }
                        </Box>
                }
            </Grid>

            <Grid item xs={12}>
                <Box width="100%" py={1} display='flex' justifyContent='center'>
                    <Button color='primary' variant='text' component={RouterLink} to="/" size="medium">
                        Get money now
                    </Button>
                    &nbsp;
                    <Button color='primary' variant='text' onClick={handleCheckAgain} size="medium">
                        Check again
                    </Button>
                </Box>
            </Grid>

        </Grid>

    if (!!collection)
        return <PayLoanStatus
            invoiceId={collection.invoiceId!}
            name={user.fullName}
            applicantId={applicantId}
            onClose={onClose}
        />


    return (
        <form onSubmit={formik.handleSubmit}>
            <Box py={2}>
                <Grid container spacing={3}>
                    {
                        error && (
                            <Grid item xs={12}>
                                <Alert severity="error">{error}</Alert>
                            </Grid>
                        )
                    }
                    {
                        formik.errors.invoiceId && (
                            <Grid item xs={12}>
                                <Alert severity="error">Please select an invoice</Alert>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Box px={inputPaddingX}>
                            {
                                loanBalance.invoices?.map((it) => (
                                    <InvoiceView
                                        canToggle={loanBalance!.invoices!.length > 1}
                                        key={it.id}
                                        data={it}
                                        onSelect={onInvoiceSelected}
                                        selected={selectedInvoice?.id || null}
                                    />
                                ))
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={inputPaddingX}>
                            <TextField
                                className={calcClasses.textField}
                                placeholder="Enter phone number"
                                hiddenLabel
                                autoComplete="off"
                                variant='outlined'
                                name='phone'
                                fullWidth
                                error={!!formik.errors.phone}
                                size='small'
                                helperText="Enter MTN or Airtel number to make a payment"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                inputProps={
                                    {
                                        style: inputStyle
                                    }
                                }
                                disabled={formik.isSubmitting}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={inputPaddingX}>
                            <TextField
                                className={calcClasses.textField}
                                placeholder="Enter amount"
                                hiddenLabel
                                autoComplete="off"
                                variant='outlined'
                                name='amount'
                                fullWidth
                                type="number"
                                error={!!formik.errors.amount}
                                size='small'
                                helperText="Enter an amount to pay"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.amount}
                                inputProps={
                                    {
                                        style: inputStyle
                                    }
                                }
                                disabled={formik.isSubmitting}
                            />
                        </Box>
                    </Grid>
                    {
                        !applicantId && (
                            <Grid item xs={12}>
                                <Box px={inputPaddingX}>
                                    <FormControlLabel
                                        name="terms"
                                        onChange={formik.handleChange}
                                        value={formik.values.terms}
                                        checked={formik.values.terms}
                                        disabled={formik.isSubmitting}
                                        onBlur={formik.handleBlur}
                                        control={<Checkbox color="primary"/>}
                                        label={<Typography style={{color: 'black'}}>
                                            I have read and agree to the
                                            &nbsp;<Link href={termsLink} target='_blank'><b>terms and
                                            conditions</b></Link>&nbsp;
                                            of the {APP_NAME_SHORT} Customer Agreement
                                        </Typography>}
                                        labelPlacement="end"
                                    />
                                </Box>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Box width="100%" py={2} px={inputPaddingX} display='flex'>
                            {
                                formik.isSubmitting &&
                                <Box py={2}>
                                    <CircularProgress/>
                                </Box>
                            }
                            <Box py={2} flexGrow={1}>
                                <Fab
                                    variant="extended"
                                    color="primary"
                                    aria-label="add"
                                    className={calcClasses.button}
                                    onClick={formik.submitForm}
                                    disabled={formik.isSubmitting || hasValue(formik.errors)}
                                >
                                    <AccountBalanceWalletIcon/>Pay now&nbsp;&nbsp;
                                </Fab>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
}


export default PayTransact;
