import React from "react";
import {XHeadCell} from "../../../components/table/XTableHead";
import ContactLink from "../../../components/links/ContactLink";
import LoanLink from "../../../components/links/LoanLink";
import {trimGuid} from "../../../utils/stringHelpers";
import {printMoney} from "../../../utils/numberHelpers";
import {printDateTime} from "../../../utils/dateHelpers";
import Chip from "@material-ui/core/Chip";
import {errorColor, successColor, warningColor} from "../../../theme/custom-colors";
import {LoanAgingModel, LoanStatus} from "../../../__generated__/apiTypes";


export const renderStatus = (value: LoanStatus) => {
    let color: any = successColor
    switch (value) {
        case LoanStatus.Closed:
            color = successColor
            break
        case LoanStatus.Error:
            color = errorColor
            break
        case LoanStatus.Open:
            color = warningColor
            break
    }

    return <Chip
        color='primary'
        variant='default'
        size='small'
        label={value}
        style={{padding: 0, height: 18, backgroundColor: color, marginBottom: 2}}
    />
}


export const renderAge = (rec: LoanAgingModel) => {
    let color: any = warningColor
    switch (rec.category) {
        case "A":
            color = successColor
            break
        case "B":
            color = warningColor
            break
        case "C":
            color = errorColor
            break
    }

    return <Chip
        color='primary'
        variant='default'
        size='small'
        label={`${rec.ageInDays} days`}
        style={{padding: 0, height: 18, backgroundColor: color, marginBottom: 2}}
    />
}

export const columns: XHeadCell[] = [
    {
        name: 'id', label: 'ID', render: value => <LoanLink id={value} name={trimGuid(value)}/>,
    },
    {
        name: 'releaseDate', label: 'Release date', render: printDateTime,
    },
    {
        name: 'amount', label: 'Principle', render: value => printMoney(value),
        cellProps: {
            style: {
                width: 60,
                padding: 0
            }
        },
        hasTotal:true
    },
    {
        name: 'interestAmount',
        label: 'Interest', render: value => printMoney(value),
        hasTotal:true
    },
    {
        name: 'penalty',
        label: 'Penalty',
        render: value => printMoney(value),
        hasTotal:true
    },
    {
        name: 'ageInDays',
        label: 'Loan Age',
        render: (_, data) => renderAge(data)
    },
    {
        name: 'daysInAreas',
        label: 'Days in arrears'
    },
    {
        name: 'applicant.name',
        label: 'Applicant',
        render: (value, rec) => <ContactLink id={rec.applicant?.id} name={rec.applicant?.name}/>,
        cellProps: {
            style: {whiteSpace: 'wrap'}
        }
    },
    {
        name: 'organization.name',
        label: 'Company',
        render: (value, rec) => <ContactLink id={rec.organization?.id} name={rec.organization?.name}/>,
        cellProps: {
            style: {whiteSpace: 'wrap'}
        }
    },
];








