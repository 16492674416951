import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {ToastContainer} from "react-toastify";
import ContentSwitch from "./modules/ContentSwitch";
import Login from "./modules/auth/Login";
import Splash from "./modules/auth/Splash";
import {useSelector} from 'react-redux'
import LoaderDialog from "./components/LoaderDialog";
import {localRoutes} from "./data/constants";
import PayLoan from "./modules/home/payback/PayLoan";
import Register from "./modules/auth/Register";
import Activate from "./modules/auth/Activate";
import ForgotPassword from "./modules/auth/ForgotPassword";
import SetPassword from "./modules/auth/SetPassword";
import Home from "./modules/home/Home";
import DetailsLoader from "./components/loaders/DetailsLoader";

const App: React.FC = () => {
    const coreState: any = useSelector((state: any) => state.core)

    const {isLoadingUser, user, globalLoader} = coreState
    if (isLoadingUser) {
        return <Splash/>
    }

    return <AppRenderer user={user} globalLoader={globalLoader}/>;
}

type AppRendererProps = {
    user: any
    globalLoader: any
}
export const AppRenderer: React.FC<AppRendererProps> = ({user, globalLoader}) => {
    const [userWait, setUserWait] = useState<boolean>(true)
    //timothy This fixes an issue where the home screen is always rendered, not sure why
    useEffect(() => {
        let timer1 = setTimeout(() => {
            setUserWait(false)
        }, 100)
        return () => {
            clearTimeout(timer1);
        };
    }, [user])

    if (userWait) return <DetailsLoader/>
    return <Router>
        <ToastContainer position='top-center'/>
        <>
            <LoaderDialog open={globalLoader}/>
            {user ?
                <ContentSwitch/> :
                <Switch>
                    <Route path={localRoutes.pay} component={PayLoan}/>
                    <Route path={localRoutes.login} component={Login}/>
                    <Route path={localRoutes.employeeRegister} component={Register}/>
                    <Route path={localRoutes.forgotPass} component={ForgotPassword}/>
                    <Route path={localRoutes.setPassword} component={SetPassword}/>
                    <Route path={localRoutes.activate} component={Activate}/>
                    <Route path="*">
                        <Home/>
                    </Route>
                </Switch>
            }
        </>
    </Router>
}

export default App;
